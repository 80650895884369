// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
// require('siema');

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import Siema from 'siema';
import 'lazysizes';
import * as Klaro from "klaro/dist/klaro-no-css";

document.addEventListener('turbolinks:load', () => {

  // Main navigation
  const menuToogleSelector = '.icmv-navbar__menu-toggle--js';
  const menuSelector = '.icmv-navbar__menu--js';
  const menuToggle = document.querySelector(menuToogleSelector);
  const menu = document.querySelector(menuSelector);
  if (menuToggle && menu) {
    menuToggle.addEventListener('click', () => {
      menu.classList.toggle('is-closed');
    });

    menu.addEventListener('click', function(e) {
      if (e.target == menuToggle) {
        e.preventDefault();
      }

      if (e.target && !e.target.classList.contains(menuSelector) && !menu.classList.contains('is-closed')) {
        menu.classList.toggle('is-closed');
      }
    });
  }

  // Search bar
  const searchToogleSelectorClass = 'icmv-navbar__search-toggle--js';
  const searchSelectorClass = 'icmv-navbar__search--js';
  const searchToggle = document.querySelector(`.${searchToogleSelectorClass}`);
  const search = document.querySelector(`.${searchSelectorClass}`);
  if (searchToggle && search) {
    searchToggle.addEventListener('click', () => {
      search.classList.toggle('is-closed');
      search.querySelector('input').focus();
    });

    search.addEventListener('click', function(e) {
      if (e.target && !search.classList.contains('is-closed') && (e.target.classList.contains(searchSelectorClass) || e.target.classList.contains('js-close'))) {
        search.classList.toggle('is-closed');
      }
    });
  }


  //============================================================================
  //=== Slider =================================================================
  //============================================================================

  Siema.prototype.addPagination = function(paginationSelector) {
    for (let i = 0; i < this.innerElements.length; i++) {
      const pageItem = document.createElement('li');
      pageItem.setAttribute('class', 'w-2 md:w-3 h-2 md:h-3 mr-2 md:mr-4 rounded-full bg-icon-blue-base');
      // pageItem.textContent = i;
      pageItem.addEventListener('click', () => this.goTo(i));
      this.selector.parentNode.querySelector(paginationSelector).appendChild(pageItem);
    }
  }

  const sliderNavSelector = '.icmv-slider__nav--js';

  function setActiveNavItem() {
    const sliderNav = this.selector.parentNode.querySelector(sliderNavSelector);
    const activeClass = 'bg-icon-red-base';
    const innerElements = sliderNav.children;

    [].forEach.call(innerElements, function(el) {
      el.classList.remove(activeClass);
    });

    innerElements[this.currentSlide].classList.add(activeClass);
  }

  const sliderSelector = '.icmv-slider';
  if (document.querySelector(sliderSelector)) {
    const slider = new Siema({
      selector: '.icmv-slider',
      loop: true,
      easing: 'ease-in-out',
      duration: 350,
      onInit: function(e) {
        this.addPagination(sliderNavSelector);
        setActiveNavItem.call(this);
      },
      onChange: setActiveNavItem
    });

    setInterval(() => slider.next(), 5000);
  }


  //============================================================================
  //=== Cookie consent =========================================================
  //============================================================================

  function announceConsent (consentGiven, service) {
    // console.log('-> announceConsent(',consentGiven,',',service,')');
    const name = service.name
    const consentGivenPart = consentGiven ? 'accepted' : 'rejected'
    sendEvent(`consent.${name}.${consentGivenPart}`)
  }
  
  function sendEvent (name, data = {}) {
    // console.log('-> sendEvent(',name,',',data,')');
    if (window.dataLayer === undefined) {
      console.warn('window.dataLayer is not defined!');
      return;
    }
    window.dataLayer.push({
        'event': name,
        ...data,
    })
  }

  const config = {
    disablePoweredBy: true,
    hideDeclineAll: true,
    acceptAll: true,
    // testing: true,
    // groupByPurpose: false,
    lang: 'de',
    services: [
      {
        name: "session",
        purposes: ["functional"],
        default: true,
        required: true,
      },
      {
        name: "googleAnalytics",
        purposes: ["analytics"],
        cookies: [
          /^_ga(_.*)?/
        ],
        callback: announceConsent,
      },
    ],
    translations: {
      de: {
        // privacyPolicyUrl: '/#datenschutz',
        consentNotice: {
          description: 'Liebe Besucher, um Sie stets aktuell und hilfreich über die Prävention von Cytomegalieinfektionen informieren zu können, verwenden wir Cookies auf unserer Website. Es handelt sich zum einen um essenzielle Cookies, die zwingend notwendig für die Funktion der Website sind. Zum anderen nutzen wir für unsere Statistik ein Cookie, das Informationen ausschließlich anonym erfasst.',
          learnMore: 'Cookie-Einstellungen ändern',
        },
        consentModal: {
          description: 'Hier können Sie die Dienste, die wir auf dieser Website nutzen möchten, bewerten und anpassen. Sie haben das Sagen! Aktivieren oder deaktivieren Sie die Dienste, wie Sie es für richtig halten.',
          privacyPolicy: {
            name: 'Datenschutzerklärung',
            text: 'Um mehr zu erfahren, lesen Sie bitte unsere {privacyPolicy}.'
          },
          title: 'Wählen Sie Ihre Cookie-Einstellungen:'
        },
        ok: 'Annehmen',
        purposes: {
          functional: 'Essenzielle Cookies, die für die Funktion der Website zwingend notwendig sind',
          analytics: 'Statistisches Cookie für anonyme Informationen',
        },
      }
    },
  };
  // we assign the Klaro module to the window, so that we can access it in JS
  window.klaro = Klaro;
  window.klaroConfig = config;
  // we set up Klaro with the config
  Klaro.setup(config);
});

require("stylesheets/application.scss")
